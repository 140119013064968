.SchoolsAndDepartmentsListPage {
    & .SchoolsAndDepartmentsListPage__heading {
        @mixin wrapper;
        margin-bottom: 48px;

        @media (--tablet) {
            margin-bottom: 96px;
        }
    }

    & .SchoolsAndDepartmentsListPage__breadcrumbs {
        @mixin researchActionHeading;
    }

    & .SchoolsAndDepartmentsListPage__intro {
        @media (--tablet) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    & .SchoolsAndDepartmentsListPage__title {
        @mixin h30;
        font-weight: 600;
        margin-bottom: 32px;

        @media (--tablet) {
            @mixin h10;
            lost-column: 6/12 2;
            min-width: min-content;
        }
    }

    & .SchoolsAndDepartmentsListPage__description {
        @mixin p10;
        margin: 0;

        @media (--tablet) {
            @mixin h50;
            flex: 1;
        }
    }
}
