.DepartmentPage {
    & .DepartmentPage__inner {
        @mixin wrapper;
    }

    & .DepartmentPage__actionHeading {
        @mixin researchActionHeading;
    }

    & .DepartmentPage__title {
        @mixin researchTitle;
    }

    & .DepartmentPage__description {
        @mixin researchDescription;
    }

    & .DepartmentPage__navigationBlockWrapper {
        @mixin researchNavigationBlockWrapper;
    }

    & .DepartmentPage__richtext {
        margin-bottom: 64px;

        @media (--tablet) {
            margin-bottom: 96px;
        }
    }
}
