.CampusStudyPage {
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        display: block;
    }

    & .CampusStudyPage__hero {
        background: var(--blackLighten60Color);
        z-index: calc(var(--studyPageNavigationZIndex) + 1);

        @media (--tablet) {
            margin-bottom: calc(-1 * var(--studyPageNavigationHeight));
        }
    }
}
