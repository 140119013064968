.SectionPage {
    & .SectionPage__content {
        display: block;
        margin-bottom: 64px;

        @media (--tablet) {
            margin-top: 64px;
        }
    }

    & .SectionPage__list {
        lost-flex-container: row;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;

        /* These styles are used to make ContentCards stretch to the list item height */

        @media (--tablet) {
            @mixin fullHeightReactChild;

            & > * {
                display: flex;
                flex-direction: column;
                height: 100%;

                & > :nth-child(2) {
                    flex: auto;
                }
            }
        }

        /* *** */

        &:first-child {
            margin-top: 0;
        }

        & > * {
            margin-bottom: 8px;

            @media (--mobileOnly) {
                width: 100%;
            }

            @media (--tablet) {
                margin-bottom: 64px;
            }
        }
    }
}
