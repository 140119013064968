@define-mixin researchActionHeading {
    padding-top: 16px;
    padding-bottom: 16px;

    @media (--tablet) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

@define-mixin researchTitle {
    @mixin h30;
    @mixin lineUnder;
    font-weight: 600;
    margin-bottom: 32px;

    @media (--tablet) {
        @mixin h20;
    }

    @media (--desktop) {
        @mixin h10;
    }
}

@define-mixin researchDescription {
    @mixin h50;
    font-weight: 600;
    margin-bottom: 32px;

    @media (--tablet) {
        @mixin h40;
        margin-bottom: 80px;
    }
}

@define-mixin researchNavigationBlockWrapper {
    margin-bottom: 48px;

    @media (--tablet) {
        @mixin stickToTop calc(var(--headerHeight) + var(--infoBannerHeight) + 16px);
        margin-bottom: 96px;
        z-index: 1;
    }
}
