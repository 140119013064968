.ArticlePage {
    &.ArticlePage--legacy {
        & .Breadcrumbs {
            margin-bottom: 24px;
        }

        & .ArticlePage__body {
            &::after {
                content: '';
                display: block;
                clear: both;
            }
        }

        & .ArticlePage__sidebar {
            display: none;
            margin-bottom: 40px;

            @media (--tablet) {
                lost-column: 1/2;
                display: block;
                float: right;
                right: calc(100% / 2 - var(--wrapperWidth) / 2);
                left: auto;
                position: relative;
            }

            /* stylelint-disable csstools/media-use-custom-media */
            @media (min-width: 768px) and (max-width: 1216px) {
                display: block;
                margin-right: 0 !important;
                right: auto;
            }
            /* stylelint-enable csstools/media-use-custom-media */
        }

        & .ArticlePage__sidebarImage {
            margin: 0;

            & img {
                width: 100%;
            }

            & figcaption {
                lost-column: 5/6;
                lost-offset: 1/6;

                @media (--desktop) {
                    lost-column: 4/6;
                    lost-offset: 2/6;
                }
            }
        }

        & .ArticlePage__bylineInfo {
            @media (--tablet) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        & .ArticlePage__otherAuthors {
            margin: 40px 0 0;

            & .ArticlePage__otherAuthorsDescription {
                @mixin caption;
                margin: 0 0 18px;
            }

            & ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                & li {
                    margin-bottom: 16px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }

        & .ArticlePage__mainAuthor {
            margin-bottom: 16px;

            @media (--tablet) {
                margin-bottom: 0;
            }
        }

        & .ArticlePage__mainAuthor,
        & .ArticlePage__otherAuthors li {
            display: flex;
            align-items: center;

            & .ArticlePage__authorName {
                @mixin label;
                margin: 0;
            }
        }

        & .ArticlePage__datesInfo {
            margin-top: 4px;

            @media (--tablet) {
                display: flex;
                align-items: center;
            }

            & span {
                @mixin caption;
                display: block;
                margin: 0 0 4px;

                @media (--tablet) {
                    margin: 0;
                }

                &:last-of-type {
                    margin: 0;

                    @media (--tablet) {
                        margin: 0 0 0 6px;
                        padding-left: 6px;
                        border-left: 1px solid var(--blackBaseLegacyColor);
                    }
                }
            }
        }

        & .ArticlePage__authorImage {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 16px;
            background-color: var(--blackBaseLegacyColor);

            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                width: 20px;
                height: 22px;
                left: 10px;
                top: 9px;
                background-color: var(--whiteBaseColor);
                mask-image: svg-load('placeholder.svg');
            }

            & img {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                object-fit: cover;
            }
        }
    }
}
