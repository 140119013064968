.StudyPage {
    overflow: hidden;
}

.StudyPage__hero {
    @mixin wrapper;
    transition: all 0.3s ease-out;

    @media (--tablet) {
        lost-flex-container: row;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    & img {
        display: block;
        width: 100%;
    }
}

.StudyPage__image {
    @media (--tablet) {
        lost-column: 7/12 2 0;
    }

    @media (--desktop) {
        lost-column: 8/12 2 0;
    }

    & .ImageWithChars__image {
        padding-top: 58.3333%;
    }
}

.StudyPage__placeholder {
    display: none;
    background: var(--redDarken60LegacyColor);

    @media (--tablet) {
        margin: 0;
        display: block;
    }

    & svg {
        width: 88px;
        height: 88px;
        display: block;
        fill: var(--redDarken40LegacyColor);
    }

    & span {
        @mixin centerElements;
        height: 100%;
    }
}

.StudyPage__data {
    background: var(--redBaseLegacyColor);
    color: var(--whiteBaseColor);
    padding: 24px 32px 32px;
    margin: 0 -32px;
    position: relative;

    &::before {
        content: '';
        display: none;
        position: absolute;
        width: 50vw;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--redBaseLegacyColor);
        z-index: -1;

        @media (--tablet) {
            display: block;
        }
    }

    @media (--tablet) {
        lost-column: 5/12 2 0;
        margin: 0;
        padding: 32px 0 32px 32px;
    }

    @media (--desktop) {
        lost-column: 4/12 2 0;
        margin: 0;
        padding: 32px 0 32px 32px;
    }

    & a {
        color: var(--whiteBaseColor);
        border-color: var(--redLighten40LegacyColor);
    }
}

.StudyPage__title {
    @mixin h2;
    margin: 8px 0 16px;
}

.StudyPage__header {
    @mixin label;
    margin-bottom: 0;
}

.StudyPage__summary {
    @mixin h4;
    margin-bottom: 40px;
}

.StudyPage__content {
    margin-top: 0;

    @media (--tablet) {
        margin-top: 64px;
        margin-bottom: 40px;

        &::after {
            display: block;
            content: '';
            clear: both;
        }
    }

    & .EducationElement__sidebar {
        margin-bottom: 64px;
    }
}

.StudyPage__body.StudyPage__body {
    margin: 0 auto;

    @media (--tablet) {
        margin-top: 64px;
    }
}

.CampusStudyPage__bottomContent,
.CampusStudyPage__secondaryContent {
    margin-top: 64px;
}

.StudyPage__leader {
    margin: 40px 0;

    @media (--tablet) {
        lost-column: 5/12;
        lost-offset: 1/12;
        margin: 64px 0 96px;
    }
}

.StudyPage__customApplicationLink {
    width: 100%;
}
