.SyllabusInfoPageBase {
    & .SyllabusInfoPageBase__body {
        @mixin wrapper;
        margin: 8px auto 0;

        @media (--tablet) {
            padding-top: 64px;
            margin: 64px auto 0;
        }

        @media print {
            padding-top: 0;
            margin-top: 40px;
        }

        & .SyllabusInfoPageBase__actionHeading {
            @mixin hideOnPrint;
            margin-bottom: 32px;

            @media (--tablet) {
                display: flex;
                align-items: center;
                margin-bottom: 24px;
            }
        }
    }
}
