.ArticleListPage {
    & .Page__layout {
        & .ArticleListPage__header {
            width: 100%;

            @media (--tablet) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        & > div {
            width: 100%;
        }
    }
}
