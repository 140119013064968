.EmployeeListPage__results {
    lost-flex-container: column;
    padding: 0;
    margin: 32px -32px 40px;

    @media (--mobileLarge) {
        margin: 32px 0 40px;
    }

    & header {
        margin-left: 32px;

        @media (--mobileLarge) {
            margin-left: 0;
        }
    }

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;

        @media (--mobileLarge) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 32px;
        }

        @media (--tablet) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (--desktop) {
            grid-template-columns: repeat(4, 1fr);
        }

        & > li:not([role='separator']) {
            margin-bottom: 8px;

            @media (--mobileLarge) {
                margin-bottom: 32px;
            }
        }
    }
}

.EmployeeListPage__inner {
    @media (--tablet) {
        lost-column: 1/2;
        lost-offset: 1/4;
    }
}

.EmployeeListPage__heading {
    @mixin p;
}
