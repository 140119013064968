.LibraryPage {
    & .LibraryPage__heading {
        @mixin wrapper;
        margin-bottom: 32px;

        @media (--tablet) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 48px;
        }

        & .LibraryPage__title {
            @mixin h20;
            margin: 0;

            @media (--tablet) {
                @mixin h10;
                lost-column: 6/12;
            }
        }

        & .LibraryPage__openingHoursInfo {
            @media (--tablet) {
                lost-column: 4/12;
            }

            @media (--desktop) {
                lost-column: 3/12;
            }
        }
    }

    & .LibraryPage__actionHeading {
        @mixin wrapper;
    }
}
