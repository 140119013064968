.CampusStudyPage {
    & .CampusStudyPage__navigation {
        & .CampusStudyPage__navigationGrid {
            @media (--tablet) {
                @mixin grid;
                grid-template-columns: repeat(4, 1fr);

                & .CampusStudyPage__navigationElement {
                    grid-column: 3 / span 2;
                }
            }

            @media (--desktop) {
                grid-template-columns: repeat(12, 1fr);

                & .CampusStudyPage__navigationElement {
                    grid-column: var(--studyPageHeroContentColumnDesktop);
                }
            }

            @media (--desktop2K) {
                & .CampusStudyPage__navigationElement {
                    grid-column: var(--studyPageHeroContentColumnDesktop2K);
                }
            }
        }
    }
}
