.PersonData {
    background: var(--redBaseLegacyColor);
    color: var(--whiteBaseColor);
    padding: 48px 32px 32px;
    margin-left: -32px;
    margin-right: -32px;
    position: relative;

    &::before {
        content: '';
        display: none;
        position: absolute;
        width: 50vw;
        height: 100%;
        top: 0;
        left: 50%;
        background: var(--redBaseLegacyColor);
        z-index: -1;

        @media (--tablet) {
            display: block;
        }
    }

    @media (--tablet) {
        margin-left: 0;
        margin-right: 0;
        padding-top: 32px;
    }

    & a {
        color: var(--whiteBaseColor);
        border-color: var(--redLighten40LegacyColor);
    }
}

.PersonData__title {
    @mixin h2;
    margin: 0 0 24px;
}

.PersonData__roles {
    @mixin list;

    & .PersonData__role::before {
        background: var(--redLighten40LegacyColor);
    }

    & .PersonData__jobTitles {
        @mixin listWithSeparator;
    }

    & .PersonData__jobTitle {
        margin: 0;

        &::before {
            content: none;
        }
    }
}

.PersonData__mail,
.PersonData__phone {
    line-height: 31px;
}
