.EmployeePage {
    overflow: hidden;

    @media (--tablet) {
        margin-bottom: 96px;
    }

    & .EmployeePage__breadcrumbs {
        @media (--mobileOnly) {
            padding-left: 32px;
            padding-right: 32px;
        }

        @media (--tablet) {
            margin-top: 56px;
        }
    }

    & .EmployeePage__content {
        padding: 0;
        display: block;

        @media (--tablet) {
            padding: 0 32px;
        }
    }
}

.EmployeePage__hero {
    @mixin wrapper;
    transition: all 0.3s ease-out;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (--tablet) {
        lost-flex-container: row;
    }

    & img {
        display: block;
        width: 100%;
    }

    & .PersonData {
        @media (--tablet) {
            lost-column: 7/12;
            position: relative;
            left: -32px;
        }
    }
}

.EmployeePage__image {
    @media (--tablet) {
        lost-column: 5/12;
    }

    & .ImageWithChars__image {
        padding-top: 78.86%;
    }
}

.EmployeePage__placeholder {
    margin: 0 -32px;
    background: var(--redDarken60LegacyColor);

    @media (--tablet) {
        margin: 0;
    }

    & svg {
        width: 88px;
        height: 88px;
        display: block;
        fill: var(--redDarken40LegacyColor);
    }

    & span {
        @mixin centerElements;
        width: 100%;
        height: 375px;

        @media (--tablet) {
            height: 100%;
        }
    }
}

.EmployeePage__areasList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & li {
        lost-column: 1/2 2;
        margin-bottom: 16px;
    }
}
