.ErrorPage {
    @mixin wrapper;
    lost-flex-container: column;
    min-height: calc(100vh - var(--headerHeight));
    justify-content: space-between;

    @media (--tablet) {
        lost-flex-container: row;
        align-items: flex-end;
        justify-content: unset;
        margin-bottom: 108px;
        min-height: 0;
        padding-top: 108px;
    }
}

.ErrorPage__content {
    @media (--tablet) {
        lost-column: 5/12;
    }

    & h1 {
        margin: 0 0 32px;

        @media (--tablet) {
            margin-top: 108px;
        }
    }
}

.ErrorPage__icons {
    position: relative;
    width: calc(100% + 64px);
    padding-top: calc(50% + 32px);
    margin-left: -32px;
    margin-right: -32px;
    margin-top: 64px;

    @media (--tablet) {
        lost-offset: 1/12;
        lost-column: 1/2;
        align-self: stretch;
        margin: 0;
        padding-top: 0;
    }
}

.ErrorPage__icon {
    width: 50%;
    padding-top: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 3;

    &:nth-child(2) {
        left: 25%;
        z-index: 2;

        & svg {
            fill: var(--redLighten40LegacyColor);
        }
    }

    &:nth-child(3) {
        left: 50%;
        z-index: 1;

        & svg {
            fill: var(--redLighten20LegacyColor);
        }
    }

    & svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        fill: var(--redLighten60LegacyColor);
    }
}

.ErrorPage__button {
    min-width: 0;

    @media (--tablet) {
        lost-column: 5/6;
    }
}
