.ArticlePage {
    &.ArticlePage--researchArticle {
        & .ArticlePage__heroIcon {
            position: absolute;
            top: -10px;
            right: 0;
            width: 84px;
            height: 114px;
            z-index: 3;

            @media (--tablet) {
                top: -107px;
                right: -22px;
                width: 158px;
                height: 214px;
            }

            & svg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
                fill: rgb(var(--redBaseLegacyRgb) / 80%);
            }
        }

        & .ArticlePage__shareButtonContainer {
            position: absolute;
            transform: translateY(-100%);
            z-index: 2;

            @media (--tablet) {
                width: 100%;
            }

            & button {
                width: 100%;
            }
        }

        & .ArticlePage__topicList {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            padding: 0;
            margin: 8px 0 0;

            & li {
                @mixin label;
                background-color: var(--blackLighten50LegacyColor);
                padding: 4px 8px;
                margin: 0 4px 4px 0;
            }
        }

        & .Page__header {
            & .ArticlePage__linkListHorizontal {
                display: flex;
                flex-wrap: wrap;

                & .ArticlePage__linkListHorizontalContent {
                    margin-bottom: 8px;
                    display: flex;

                    &:not(:last-child)::after {
                        content: '|';
                        padding: 0 8px;
                    }
                }
            }
        }

        & .ArticlePage__authorList {
            list-style-type: none;
            padding: 0;
            margin: 0 0 48px;

            @media (--tablet) {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                margin-bottom: 96px;
            }

            & .ArticlePage__authorListItem {
                background-color: var(--redDarken40LegacyColor);
                width: 100%;
                margin-bottom: 32px;

                @media (--mobileOnly) {
                    max-width: 350px;
                }

                @media (--tablet) {
                    lost-column: 6/12 2;
                    display: flex;
                }

                & .ArticlePage__authorImage {
                    @mixin aspectRatio 208/312;
                    width: 100%;

                    @media (--tablet) {
                        @mixin aspectRatio 1/1;
                        lost-column: 2/6;
                    }

                    & img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                & .ArticlePage__placeholder {
                    & svg {
                        position: absolute;
                        top: 25%;
                        left: 35%;
                        width: 30%;
                        height: 50%;
                        fill: rgb(var(--redBaseLegacyRgb) / 40%);

                        @media (--tablet) {
                            left: 25%;
                            width: 50%;
                        }
                    }
                }

                & .ArticlePage__authorContent {
                    width: 100%;
                    color: var(--whiteBaseColor);
                    padding: 30px 32px;

                    @media (--tablet) {
                        lost-column: 4/6;
                        padding: 30px 32px 30px 0;
                    }

                    & > p {
                        margin-bottom: 8px;

                        &:empty {
                            margin: 0;
                        }
                    }

                    & .ArticlePage__authorJobTitles {
                        margin-bottom: 8px;
                    }

                    & .ArticlePage__authorName {
                        font-size: 18px;
                        margin-bottom: 12px;

                        @media (--tablet) {
                            margin-bottom: 20px;
                        }

                        & a {
                            margin: 0;
                            color: var(--whiteBaseColor);
                        }
                    }
                }
            }
        }
    }
}
