.ResearchArticleListPage {
    & .ResearchArticleListPage__hero {
        position: relative;
        background-color: var(--redDarken40LegacyColor);
        margin: calc(-1 * var(--headerMobileHeight)) 0 25px;

        @media (--tablet) {
            margin: calc(-1 * var(--headerHeight)) 0 50px;
        }

        & .ResearchArticleListPage__image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            & picture {
                display: block;
                height: 100%;
                width: 100%;
            }

            & img {
                display: block;
                object-fit: cover;
                max-width: none;
                width: 100%;
                height: 100%;
            }
        }

        & .ResearchArticleListPage__wrapper {
            @mixin wrapper;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .ResearchArticleListPage__headerContent {
                position: relative;
                color: var(--whiteBaseColor);
                z-index: 4;
                margin: 146px 0 64px;

                @media (--tablet) {
                    lost-column: 6/12;
                    margin-top: 152px;
                    margin-bottom: 80px;
                }

                & h1 {
                    @mixin h1;
                    margin: 0 0 8px;

                    @media (--tablet) {
                        margin-bottom: 16px;
                    }
                }

                & p {
                    @mixin h4;
                    margin: 0;
                }
            }

            & .ResearchArticleListPage__icon {
                display: block;
                z-index: 3;

                @media (--mobileOnly) {
                    position: absolute;
                    bottom: -9px;
                    right: 0;
                    width: 150px;
                    height: 150px;
                }

                @media (--tablet) {
                    @mixin aspectRatio 1/1;
                    lost-column: 2/12;
                    min-width: 150px;
                    align-self: flex-end;
                    position: relative;
                    margin-bottom: -24px;
                }

                & svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    fill: var(--redDarken20LegacyColor);
                }
            }
        }
    }

    & .ResearchArticleListPage__content {
        @mixin wrapper;
        background-color: var(--whiteBaseColor);
        margin-bottom: 82px;

        @media (--tablet) {
            margin-bottom: 93px;
        }
    }
}
