.OnlineStudyPage__studyPlanDescription {
    & p {
        margin: 0;
    }
}

.OnlineStudyPage__callToActionBanner {
    & .CallToActionBanner {
        margin: 0 -32px;

        @media (--tablet) {
            margin: 0;
        }

        & .OnlineStudyPage__switchTabContainer {
            & button {
                min-width: 243px;
                margin: 0;
            }
        }
    }
}

.OnlineStudyPage {
    @media (--tablet) {
        overflow: visible;
    }

    & .EducationElement__sidebar {
        margin-bottom: 64px;

        & .OnlineStudyPage__switchTabContainer {
            & button {
                width: 100%;
            }
        }
    }

    & .OnlineStudyPage__accordions {
        margin: 0 -32px 64px;

        @media (--tablet) {
            margin: 64px auto;
        }
    }

    & .StudyPage__content {
        margin: 40px 0;
    }
}

.OnlineStudyPage__moreStudyInfo {
    & .OnlineStudyPage__accordions {
        & th {
            padding: 16px 0 8px;

            @media (--tablet) {
                padding: 24px 0;
            }
        }

        & td {
            padding: 8px 0 16px;

            @media (--tablet) {
                padding: 24px 0;
            }
        }
    }
}

.OnlineStudyPage__heroContainer {
    width: 100vw;
    overflow: hidden;

    & .StudyPage__switchToTabButtonContainer {
        height: 48px;
    }
}

.OnlineStudyPage__tabsBar,
.OnlineStudyPage__studyPlan--legacy {
    margin: 0 -32px;

    @media (--tablet) {
        margin: 0;
    }
}

.OnlineStudyPage__secondaryContent {
    margin-top: 64px;
}

.OnlineStudyPage__studyPlan {
    &:not(.OnlineStudyPage__studyPlan--legacy) {
        @mixin extendedWrapperVariables;
    }

    &.OnlineStudyPage__studyPlan--legacy {
        @mixin wrapper;

        & > div {
            margin-bottom: 40px;

            @media (--tablet) {
                margin-bottom: 96px;
            }
        }
    }
}

/* NB! Used on various pages */
.EducationElement__sidebar {
    margin: 0 -32px;

    @media (--tablet) {
        lost-column: 1/2;
        margin-right: 0 !important;
        margin-left: 32px !important;
        float: right;
    }
}
