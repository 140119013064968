.PersonOverview {
    padding: 32px;

    @media (--tablet) {
        padding: 32px 0;
        lost-column: 2/3;
        lost-offset: 1/6;
    }

    @media (--desktop) {
        lost-column: 1/2;
        lost-offset: 3/12;
    }
}

.PersonOverview__heading {
    @mixin h3;
    margin: 0 0 32px;
}
