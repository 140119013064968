.SyllabusListSearch {
    & .Page__hero {
        padding-top: 16px;

        @media (--tablet) {
            padding-top: 56px;
        }
    }

    & .Page__heading,
    & .Page__intro {
        @media (--tablet) {
            max-width: 50%;
        }
    }

    & .Page__intro {
        margin: 16px 0 0;
    }

    & .Page__body {
        margin-top: 36px;
    }
}
