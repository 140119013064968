.QuizPage {
    & .QuizPage__heroWrapper {
        position: relative;
        overflow: hidden;
        margin-top: calc(-1 * var(--headerMobileHeight));

        @media (--tablet) {
            margin-top: calc(-1 * var(--headerHeight));
        }
    }

    & .QuizPage__hero {
        position: relative;
    }
}
