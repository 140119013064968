.SubjectPage__letters {
    margin: 0 -32px;
    position: relative;
    display: flex;

    &::before {
        content: '';
        display: none;
        position: absolute;
        width: 50vw;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--blackLighten60LegacyColor);
        z-index: -1;

        @media (--tablet) {
            display: block;
        }
    }

    @media (--mobileOnly) {
        background-color: var(--whiteBaseColor);
    }

    @media (--tablet) {
        lost-column: 6/12 2 0;
        margin: 0;
    }

    & > div {
        flex: 1;
        margin: 0 0 -16px;

        @media (--desktop) {
            margin: -8px -32px -16px 0;
        }

        @media (--desktopHD) {
            margin-right: -100px;
        }
    }
}

.SubjectPage {
    overflow: hidden;

    & .EducationElement__sidebar {
        & td,
        & th {
            padding: 24px 0;
            border-bottom: 1px solid var(--blackLighten50LegacyColor);
            vertical-align: top;
            display: table-cell;
        }

        & th {
            text-align: left;
            font-weight: 500;

            & > div {
                position: relative;
                padding-left: 40px;
                top: -1px;
            }
        }

        & td {
            padding-left: 16px;
        }
    }

    & .SubjectPage__accordions {
        margin-bottom: 96px;

        @media (--tablet) {
            margin-top: 64px;
        }
    }
}

.SubjectPage__hero {
    @mixin wrapper;
    background: var(--blackLighten60LegacyColor);
    display: flex;
    flex-direction: column-reverse;
    transition: all 0.3s ease-out;

    @media (--tablet) {
        lost-flex-container: row;
        margin-bottom: 24px;
        margin-top: 24px;
    }
}

.SubjectPage__data {
    padding: 32px 0 64px;

    @media (--tablet) {
        lost-column: 5/12 2 0;
    }
}

.SubjectPage__header {
    @mixin label;
}

.SubjectPage__title {
    @mixin h2;
    margin-bottom: 16px;
}

.SubjectPage__summary {
    @mixin h4;
    margin-bottom: 40px;
}

.SubjectPage__body {
    margin: 40px auto 0;
}

.SubjectPage__content {
    @media (--tablet) {
        &::after {
            display: block;
            content: '';
            clear: both;
        }
    }

    & .EducationElement__sidebar {
        margin-bottom: 64px;
    }
}

.SubjectPage__callToActionBanner {
    & .CallToActionBanner {
        color: var(--blackBaseLegacyColor);
        background-color: var(--blackLighten60LegacyColor);
    }

    & .CallToActionBanner__title {
        margin-bottom: 16px;
    }
}

.SubjectPage__parentStudies {
    margin-bottom: 96px;

    & .Richtext__content {
        & ul {
            margin-top: 16px;
        }

        & a {
            border-color: var(--redLighten40LegacyColor);
        }
    }
}
