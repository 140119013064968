.SubjectInfoPage {
    & .SubjectInfoPage__callToActionBanner {
        & .CallToActionBanner {
            color: var(--blackBaseLegacyColor);
            background-color: var(--blackLighten60LegacyColor);
            margin-top: 64px;

            @media (--tablet) {
                margin: 0;
            }
        }

        & .CallToActionBanner__title {
            margin-bottom: 16px;
        }
    }
}
