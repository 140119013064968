@define-mixin titleTopSectionsBelow {
    display: grid;
    grid-template-columns: 7fr 1fr 4fr;
    grid-template-areas:
        'info . aside'
        'content . aside';
    column-gap: 0;

    & .Layout__info {
        grid-area: info;
    }

    & .Layout__aside {
        grid-area: aside;
    }

    & .Layout__content {
        grid-area: content;
    }
}

.ResearchProject {
    & .ResearchProject__actionHeading {
        @mixin wrapper;
        @mixin researchActionHeading;
    }

    & .ResearchProject__baseInfo {
        @mixin wrapper;
    }

    & .ResearchProject__navigationBlockWrapper {
        @mixin researchNavigationBlockWrapper;
    }

    & .ResearchProject__title {
        @mixin researchTitle;
        lost-column: 10/12 flex;
    }

    & .ResearchProject__description {
        @mixin researchDescription;
    }

    & .ResearchProject__detailedInfo {
        @mixin clearList;
        @mixin p20;
        padding: 32px 0;
        border-top: 1px solid var(--blackLighten20Color);
        border-bottom: 1px solid var(--blackLighten20Color);
        margin-bottom: 48px;

        @media (--tablet) {
            display: flex;
            flex-wrap: wrap;
            padding: 8px 0;
            margin-bottom: 80px;
        }

        & li {
            padding-bottom: 8px;

            @media (--tablet) {
                margin-right: 48px;
            }

            &:last-child {
                padding-bottom: 0;
                margin-right: 0;
            }
        }
    }

    & .ResearchProject__richtext {
        margin-bottom: 64px;

        @media (--tablet) {
            margin-bottom: 96px;
        }

        /* TODO: Remove this after syncing Richtext font size with new design */

        & p {
            @mixin p10;
        }
    }

    & .ResearchProject__infoItemHeading {
        @mixin h40;
    }
}

.Layout__titleTopSectionsBelow {
    @media (--tablet) {
        @mixin titleTopSectionsBelow;
    }
}
