.ContactUsPage__intro {
    margin-top: 32px;

    &.Richtext > .Richtext__content {
        @media (--tablet) {
            lost-column: 1/2;
            lost-offset: 0;
        }

        & a {
            border-bottom-color: var(--redLighten40LegacyColor);

            &:hover {
                border-bottom-color: var(--redBaseLegacyColor);
            }
        }
    }
}

.ContactUsPage__calloutLists {
    margin: 24px -32px 0;

    @media (--tablet) {
        margin: 64px 0 0;
    }
}

.ContactUsPage__address {
    margin-top: 32px;

    @media (--tablet) {
        lost-column: 1/2;
    }

    & a {
        border-bottom-color: var(--redLighten40LegacyColor);

        &:hover {
            border-bottom-color: var(--redBaseLegacyColor);
        }
    }
}
