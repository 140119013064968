.CampusStudyPage {
    & .CampusStudyPage__navigation {
        @mixin wrapper;
        position: sticky;
        order: 1;
        z-index: var(--studyPageNavigationZIndex);

        @media (--mobileOnly) {
            bottom: 0;

            & .CampusStudyPage__navigationElement {
                margin: 0 calc(-2 * var(--studyPageNavigationTabletHorizontalPadding));
            }
        }

        @media (--tablet) {
            @mixin topHideAnimation;
            pointer-events: none;
            top: calc(var(--headerHeight) + 32px + var(--studyPageNavigationHeight));

            & .CampusStudyPage__navigationElement {
                transform: translateY(calc(-100% - 8px));
                pointer-events: all;
            }
        }
    }
}

$(hiddenHeaderClassSelector) {
    @media (--tablet) {
        & .CampusStudyPage__navigation {
            top: calc(32px + var(--studyPageNavigationHeight));
        }
    }
}

.InfoBanner--active:not($(hiddenHeaderClassSelector)) {
    $headerAndInfoBannerOffset: calc(
        var(--headerHeight) + var(--infoBannerHeight) + 32px + var(--studyPageNavigationHeight)
    );

    @media (--tablet) {
        & .CampusStudyPage__navigation {
            top: $headerAndInfoBannerOffset;
        }
    }
}

.StudyPageNavigation--open {
    @media (--mobileOnly) {
        & .CampusStudyPage__navigation {
            z-index: calc(var(--studyPageNavigationZIndex) + 1);
        }
    }
}
