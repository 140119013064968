.MyAdminPageIndex {
    @mixin wrapper;

    & .MyAdminPageIndex__hero {
        @media (--desktop) {
            padding: 24px 0 32px;
            margin: 0 0 32px;
        }
    }

    & .MyAdminPageIndex__heading {
        @mixin h2;
        font-weight: 500;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }

    & .MyAdminPageIndex__body {
        margin-top: 0;
        margin-bottom: 32px;

        @media (--tablet) {
            margin-top: 32px;
        }
    }

    & .MyAdminPageIndex__inner {
        @media (--desktop) {
            lost-offset: 3/12;
            lost-column: 6/12;
        }
    }

    & .MyAdminPageIndex__container {
        background: var(--blackLighten60Color);
        padding: 24px 32px 48px;
    }
}
