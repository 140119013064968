.ArticlePage {
    overflow: hidden;

    & .ArticlePage__body {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 96px;
    }

    &.ArticlePage--modern {
        & .Page__title {
            @mixin h30;
            margin-bottom: 24px;
            text-wrap: balance;

            @media (--tablet) {
                @mixin h20;
                lost-column: 10/12;
                margin-bottom: 64px;
            }
        }

        & .ArticlePage__hero {
            position: relative;
            margin-bottom: 48px;

            & .HeroLegacy {
                padding: 0;

                & .HeroLegacy__media {
                    margin-top: 0;
                    margin-bottom: 0;

                    & .HeroLegacy__imageCaption {
                        display: none;

                        @media (--tablet) {
                            display: block;
                            lost-column: 6/12;
                        }
                    }
                }
            }
        }

        & .ArticlePage__summaryBox {
            width: 100vw;
            margin-left: calc(-1 * var(--wrapperHorizontalPadding));

            @media (--tablet) {
                position: absolute;
                width: 100%;
                max-width: 250px;
                top: 0;
                right: calc(-1 * var(--wrapperHorizontalPadding));
                margin-top: calc(64px * 7 / 12);
                padding-top: calc(100% * 7 / 12);
                z-index: 2;
            }

            @media (--aboveWrapper) {
                right: 0;
                margin-top: 0;
            }
        }

        & .ArticlePage__infoContainer {
            background-color: var(--blackLighten60LegacyColor);
            padding: 16px 0;

            @media (--tablet) {
                padding: 32px;
            }

            & > ul {
                @mixin clearList;

                & > li {
                    padding: 16px 32px;
                    border-bottom: 1px solid var(--blackLighten50LegacyColor);

                    @media (--tablet) {
                        padding: 16px 0;
                    }

                    &:last-child {
                        border: none;
                    }
                }

                & h3 {
                    @mixin label;
                    margin: 0 0 8px;
                }

                & p,
                & a {
                    @mixin label;
                    font-weight: 400;
                    margin: 0;
                }
            }

            & .ArticlePage__authorsInfo {
                @media (--mobileOnly) {
                    & .ArticlePage__authorName,
                    & p {
                        display: inline-block;
                    }
                }

                & .ArticlePage__authorInfoHeading {
                    margin-bottom: 8px;

                    @media (--tablet) {
                        margin-bottom: 16px;
                    }
                }

                & .ArticlePage__authorsList {
                    @mixin clearList;

                    & li {
                        &:not(:last-child) {
                            & .ArticlePage__authorBylineInfo {
                                margin-bottom: 8px;

                                @media (--tablet) {
                                    @mixin lineUnder 8px, 32px, 1px, var(--blackLighten50Color);
                                }
                            }
                        }
                    }
                }
            }

            & .ArticlePage__dateInfo {
                @media (--mobileOnly) {
                    border: 0;
                }
            }
        }

        & .ArticlePage__authorJobTitles {
            @mixin listWithSeparator;
        }

        & .ArticlePage__authorBylineInfoJobTitle {
            @mixin p40;
        }

        & .ArticlePage__linkList {
            @mixin clearList;
        }

        & .Page__header {
            @media (--tablet) {
                lost-column: 8/12;
            }

            & .Page__intro {
                @media (--tablet) {
                    @mixin h40;
                }
            }
        }

        & .SidebarImage {
            @media (--tablet) {
                margin-left: 32px;
            }
        }

        & .Richtext {
            @media (--tablet) {
                font-size: 18px;
            }

            & .Richtext__content {
                @media (--tablet) {
                    lost-offset: 1/12;
                    lost-column: 7/12;
                }
            }

            & .Richtext__blockquote {
                display: inline-block;
            }

            & .VideoFigure {
                @media (--tablet) {
                    lost-column: 10/12;
                }
            }
        }
    }
}
