@define-mixin elementsLeftAsideRight {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-template-areas:
        'info aside'
        'links aside'
        'content aside';
    column-gap: 40px;

    & .Layout__info {
        grid-area: info;
    }

    & .Layout__links {
        grid-area: links;
    }

    & .Layout__content {
        grid-area: content;
    }

    & .Layout__aside {
        grid-area: aside;
    }
}

/* Used by both School and Department pages */
.Layout__elementsLeftAsideRight {
    @media (--tablet) {
        @mixin elementsLeftAsideRight;
    }
}
