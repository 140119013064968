.PersonDetails {
    @media (--tablet) {
        margin: 40px 0;
    }

    @media (--desktop) {
        lost-column: 2/3;
        lost-offset: 1/6;
    }
}
